import(/* webpackMode: "eager" */ "/home/runner/work/gitbook-open/gitbook-open/node_modules/next/dist/shared/lib/app-router-context.shared-runtime.js");
import(/* webpackMode: "eager" */ "/home/runner/work/gitbook-open/gitbook-open/node_modules/next/dist/shared/lib/hooks-client-context.shared-runtime.js");
import(/* webpackMode: "eager" */ "/home/runner/work/gitbook-open/gitbook-open/node_modules/next/dist/shared/lib/server-inserted-html.shared-runtime.js");
import(/* webpackMode: "eager" */ "/home/runner/work/gitbook-open/gitbook-open/packages/react-contentkit/src/ContentKit.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/gitbook-open/gitbook-open/packages/react-contentkit/src/ElementButton.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/gitbook-open/gitbook-open/packages/react-contentkit/src/ElementCard.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/gitbook-open/gitbook-open/packages/react-contentkit/src/ElementMarkdownClient.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/gitbook-open/gitbook-open/packages/react-contentkit/src/ElementModal.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/gitbook-open/gitbook-open/packages/react-contentkit/src/ElementTextInput.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/gitbook-open/gitbook-open/packages/react-contentkit/src/ElementWebframe.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/gitbook-open/gitbook-open/packages/react-math/src/default.css");
import(/* webpackMode: "eager" */ "/home/runner/work/gitbook-open/gitbook-open/packages/react-math/src/KaTeXCSS.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/gitbook-open/gitbook-open/packages/react-math/src/MathJaXLazy.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/gitbook-open/gitbook-open/packages/react-openapi/src/InteractiveSection.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/gitbook-open/gitbook-open/packages/react-openapi/src/OpenAPIServerURLVariable.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/gitbook-open/gitbook-open/packages/react-openapi/src/OpenAPISpec.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/gitbook-open/gitbook-open/packages/react-openapi/src/ScalarApiButton.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/gitbook-open/gitbook-open/src/app/(space)/(content)/[[...pathname]]/PageClientLayout.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/gitbook-open/gitbook-open/src/components/DocumentView/Annotation/AnnotationPopover.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/gitbook-open/gitbook-open/src/components/DocumentView/CodeBlock/CopyCodeButton.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/gitbook-open/gitbook-open/src/components/DocumentView/CodeBlock/theme.css");
import(/* webpackMode: "eager" */ "/home/runner/work/gitbook-open/gitbook-open/src/components/DocumentView/Integration/contentkit.css");
import(/* webpackMode: "eager" */ "/home/runner/work/gitbook-open/gitbook-open/src/components/DocumentView/ListItem.module.css");
import(/* webpackMode: "eager" */ "/home/runner/work/gitbook-open/gitbook-open/src/components/DocumentView/OpenAPI/style.css");
import(/* webpackMode: "eager" */ "/home/runner/work/gitbook-open/gitbook-open/src/components/DocumentView/OpenAPI/scalar.css");
import(/* webpackMode: "eager" */ "/home/runner/work/gitbook-open/gitbook-open/src/components/DocumentView/Table/table.module.css");
import(/* webpackMode: "eager" */ "/home/runner/work/gitbook-open/gitbook-open/src/components/DocumentView/Tabs/DynamicTabs.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/gitbook-open/gitbook-open/src/components/PageAside/ScrollSectionsList.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/gitbook-open/gitbook-open/src/components/PageBody/default-page-cover.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/gitbook-open/gitbook-open/src/components/PageBody/TrackPageView.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/gitbook-open/gitbook-open/src/components/PageFeedback/PageFeedbackForm.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/gitbook-open/gitbook-open/src/components/primitives/Button.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/gitbook-open/gitbook-open/src/components/primitives/Checkbox.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/gitbook-open/gitbook-open/src/components/primitives/DateRelative.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/gitbook-open/gitbook-open/src/components/primitives/Link.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/gitbook-open/gitbook-open/src/components/utils/Zoom.tsx")